import * as React from 'react'

import { Box } from '../../shared/Helpers'
import Link from '../../shared/Link'
import { Tab, TabItem } from '../../shared/Tabs'
import { Context } from '../Consumer'
import { IGlossary } from '../types'
import { SidebarStick } from './parts'

interface IProp {
  getRefs: () => HTMLElement[]
}

const SidebarMobile: React.FC<IProp> = ({ getRefs }) => {
  const { data }: { data: IGlossary } = React.useContext(Context)
  const [currentTerm, setCurrentTerm] = React.useState('a')
  const letters = Object.keys(data).filter(letter => data[letter].length > 0)

  const onScroll = () => {
    const activeTerm = getRefs()
      .reverse()
      .find(item => item.getBoundingClientRect().top < 100)

    if (activeTerm !== undefined) {
      return setCurrentTerm(
        activeTerm
          .getAttribute('id')!
          .split('')
          .slice(0, 1)[0] || ''
      )
    }

    return null
  }

  const renderOptions = letters.map(letter => (
    <Box key={letter}>
      <TabItem mb=".7rem" mt=".7rem" active={currentTerm === letter}>
        <Link to={`/glossary/#${letter}`}>{letter.toUpperCase()}</Link>
      </TabItem>
    </Box>
  ))

  React.useEffect(() => {
    onScroll()
    document.addEventListener('scroll', onScroll)
    return () => document.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <SidebarStick display={['block', 'none']}>
      <Tab>{renderOptions}</Tab>
    </SidebarStick>
  )
}

export default SidebarMobile
