import * as React from 'react'

import PostContent from '../../Blog/PostContent'
import { Box } from '../../shared/Helpers'
import Link from '../../shared/Link'
import Copy from '../../shared/RenderHtml'
import { BlogText, Title } from '../../shared/Typography'
import { Context } from '../Consumer'
import { IFrontMatterTerm, IGlossary } from '../types'
import { TermContent } from './parts'

const linkIcon = require('../../../images/icons/link.svg')

interface IProp {
  pushRef: (key: string, node: HTMLElement) => boolean | Map<any, any>
}

const GlossaryContent: React.FC<IProp> = ({ pushRef }) => {
  const { data }: { data: IGlossary } = React.useContext(Context)

  return (
    <>
      {Object.keys(data).map(letter => {
        const terms = data[letter]

        if (terms.length === 0) {
          return null
        }

        return (
          <div key={letter} id={letter}>
            <Title mb="1em">{letter.toUpperCase()}</Title>
            {terms.map((t: IFrontMatterTerm, index) => (
              <Box
                key={t.term}
                pt="2rem"
                pb={terms.length - 1 === index && '3rem'}
                id={t.slug}
                ref={(node: HTMLElement) => pushRef(t.term, node)}
              >
                <TermContent as="h2" mb=".5rem" color="blue">
                  <Copy html={t.term} />
                  <Link to={`/glossary/#${t.slug}`}>
                    <img src={linkIcon} alt="link" />
                  </Link>
                </TermContent>

                <BlogText pb="3rem" color="greyMedium">
                  <PostContent html={t.html || ''} />
                </BlogText>
              </Box>
            ))}
          </div>
        )
      })}
    </>
  )
}

export default GlossaryContent
