import * as React from 'react'

import { Box } from '../../shared/Helpers'
import Link from '../../shared/Link'
import { Text } from '../../shared/Typography'
import { Context } from '../Consumer'
import { IGlossary } from '../types'
import { Sidebar, TermSidebar } from './parts'

interface IProp {
  getRefs: () => HTMLElement[]
}

const SidebarGlossary: React.FC<IProp> = ({ getRefs }) => {
  const { data }: { data: IGlossary } = React.useContext(Context)
  const [currentTerm, setCurrentTerm] = React.useState('a')

  const onScroll = () => {
    const activeTerm = getRefs()
      .reverse()
      .find(item => item.getBoundingClientRect().top < 100)

    if (activeTerm !== undefined) {
      return setCurrentTerm(activeTerm.getAttribute('id') || '')
    }

    return null
  }

  React.useEffect(() => {
    onScroll()
    document.addEventListener('scroll', onScroll)
    return () => document.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <Box minWidth="30%" display={['none', 'block']}>
      <Sidebar>
        {Object.keys(data).map(letter => {
          const terms = data[letter]
          const activeLetter = letter === currentTerm.charAt(0)
          const letterColor = activeLetter ? 'blue' : 'greyMedium'

          if (terms.length === 0) {
            return (
              <Text key={letter} color="greyLight" mb="1rem">
                {letter.toUpperCase()}
              </Text>
            )
          }

          return (
            <React.Fragment key={letter}>
              <Text key={letter} mb="1rem" color={letterColor}>
                <Link to={`/glossary/#${letter}`}>{letter.toUpperCase()}</Link>
              </Text>

              {activeLetter && (
                <Box mb="1rem">
                  {terms.map(t => (
                    <TermSidebar key={t.term} current={t.slug === currentTerm}>
                      <Link to={`/glossary/#${t.slug}`}>{t.term}</Link>
                    </TermSidebar>
                  ))}
                </Box>
              )}
            </React.Fragment>
          )
        })}
      </Sidebar>
    </Box>
  )
}

export default SidebarGlossary
