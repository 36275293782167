import { graphql, StaticQuery } from 'gatsby'
import * as React from 'react'

import { Box } from '../../shared/Helpers'
import { Description, MainTitle } from '../../shared/Typography'

export interface IHero {
  title: string
  description: string
}

export interface IProp {
  glossaryYaml: IHero
}

export default function queryHeroGlossary() {
  return (
    <StaticQuery
      query={graphql`
        query GlossaryHero {
          glossaryYaml {
            title
            description
          }
        }
      `}
      render={({ glossaryYaml: data }: IProp) => {
        return (
          <Box width={['100%', '50%']} mb="5em">
            <MainTitle mb="1rem" mt="4rem">
              {data.title}
            </MainTitle>
            {data.description && (
              <Description color="greyMedium">{data.description}</Description>
            )}
          </Box>
        )
      }}
    />
  )
}
