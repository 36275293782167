import * as React from 'react'

const useCollectionNodes = () => {
  const allNodes = React.useRef(new Map()).current

  return {
    getAllNodes: () => Array.from(allNodes.values()),
    pushNode: (key: string, item: HTMLElement) => {
      return item === null ? allNodes.delete(key) : allNodes.set(key, item)
    },
  }
}

export default useCollectionNodes
