import {
  blogTextStyle,
  captionStyle,
  descriptionStyle,
  footNoteStyle,
  subTitleStyle,
} from '../../shared/Typography'
import styled from '../../styled'

export const Wrapper = styled.div`
  max-width: 46em;
  margin-left: auto;
  margin-right: auto;

  p {
    ${blogTextStyle}
    margin-bottom: 1em;
  }

  ol,
  ul {
    margin-left: 1em;
    margin-bottom: 1em;
  }

  li {
    list-style: disc;
    margin-bottom: 1em;
    ${blogTextStyle}
  }

  h1,
  h2 {
    ${captionStyle}
    margin: 3em 0 1.5em 0;
  }

  h3,
  h4,
  h5,
  h6 {
    ${descriptionStyle}
    margin: 3em 0 1.5em 0;
    font-weight: 500;
  }

  a {
    color: ${({ theme: { colors } }) => colors.blue.default};
    display: inline;

    &:hover {
      text-decoration: underline;
    }
  }

  table {
    width: 100%;
    margin-bottom: 2em;

    th,
    td {
      text-align: left;
      padding: 0.5em;
      border-bottom: 1px solid
        ${({ theme: { colors } }) => colors.grayscale.light};
    }
  }

  hr {
    border-bottom: 1px solid
      ${({ theme: { colors } }) => colors.grayscale.light};
    margin: 2em 0 3em 0;
  }

  img {
    display: inline-block;
    border-radius: 4px;
  }

  blockquote {
    padding-left: 2em;
    margin: 3.5rem 0;
    border-left: 1px solid ${({ theme: { colors } }) => colors.blue.default};

    p {
      ${subTitleStyle}
      font-weight: normal;
      margin-bottom: 1em;
      color: ${({ theme: { colors } }) => colors.grayscale.dark};
    }
  }

  twitter-widget {
    margin-left: auto;
    margin-right: auto;
    margin-top: 2.5em !important;
    margin-bottom: 2.5em !important;
  }

  .gatsby-resp-iframe-wrapper,
  .gatsby-resp-image-wrapper {
    margin: 3em 0;
  }

  img + em,
  .gatsby-resp-image-link + em {
    ${footNoteStyle};
    text-align: center;
    font-style: normal;
    display: block;
    color: ${({ theme: { colors } }) => colors.grayscale.default};
    margin-top: -2em;
    padding-bottom: 1em;
  }

  img + em {
    margin-top: 1em;
  }
`
