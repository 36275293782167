import GatsbyLink from 'gatsby-link'
import * as React from 'react'

import { generateCategoryUrl } from '../../../utils/generateUrl'
import withGlossary from '../../Glossary/withGlossary'
import { Box } from '../../shared/Helpers'
import { createGlobalStyle } from '../../styled'
import { CategoryTag } from '../PostHeader/parts'
import { Wrapper } from './parts'

const themePrismJs = require('./customPrismJS.css')

interface IProp {
  html: string
  categories?: string[]
}

const ApplyThemePrismJs = createGlobalStyle`
  ${themePrismJs};
`

const PostContent: React.FC<IProp> = ({ html, categories }) => {
  const Glossary = withGlossary()

  const CategoryList = () => (
    <>
      {Array.isArray(categories) && (
        <Box mt="3em">
          {categories.map(cat => (
            <CategoryTag key={cat}>
              <GatsbyLink to={generateCategoryUrl(cat)}>{cat}</GatsbyLink>
            </CategoryTag>
          ))}
        </Box>
      )}
    </>
  )

  return (
    <>
      <ApplyThemePrismJs />
      <Wrapper>
        <Glossary>{html}</Glossary>
        <CategoryList />
      </Wrapper>
    </>
  )
}

export default PostContent
