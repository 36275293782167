import { Box } from '../../shared/Helpers'
import styled from '../../styled'

export const Image = styled(Box)`
  padding-top: 40%;
  position: relative;
  overflow: hidden;
  border-radius: 6px;

  @media screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints[0]}) {
    padding-top: 60%;
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const Wrapper = styled.div`
  text-align: center;
`

export const CategoryTag = styled.div`
  background: ${({ theme: { colors } }) => colors.blue.light};
  border-radius: 2px;
  color: ${({ theme: { colors } }) => colors.blue.default};
  display: inline-block;
  font-size: 0.75rem;
  line-height: 1;
  margin-right: 0.5em;
  padding: 0.5em 0.5em 0.3em;
  text-transform: uppercase;
`
