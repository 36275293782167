import { Box } from '../../shared/Helpers'
import { Description, FootNote } from '../../shared/Typography'
import styled, { css } from '../../styled'

export const Sidebar = styled.div`
  position: sticky;
  top: 10px;
  margin-bottom: 3em;
`

export const TermSidebar = styled(FootNote)`
  ${({ theme: { colors, transition }, current }) => css`
    color: ${colors.grayscale.medium};
    border-left: 1px solid ${colors.grayscale.light};
    padding-left: 1em;
    line-height: 2;
    transition: all ${transition.ease(300)};

    a {
      transition: all ${transition.ease()};
      color: inherit;
    }

    ${current &&
      css`
        color: ${colors.blue.default};
        border-left: 1px solid ${colors.blue.default};
      `}
  `};
`

export const TermContent = styled(Description)`
  position: relative;
  display: inline-block;

  a {
    opacity: 0;
    position: absolute;
    right: -1em;
    top: 5px;
    transition: opacity ${({ theme: { transition } }) => transition.ease()};
  }

  &:hover a {
    opacity: 1;
  }
`

export const SidebarStick = styled(Box)`
  margin-bottom: 2em;
  position: sticky;
  top: 0;
  max-width: calc(100vw - 3em);
  z-index: 999;
  background: #fff;
`
