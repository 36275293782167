import * as React from 'react'

import Content from '../components/Glossary/Page/Content'
import Hero from '../components/Glossary/Page/Hero'
import Sidebar from '../components/Glossary/Page/Sidebar'
import SidebarMobile from '../components/Glossary/Page/SidebarMobile'
import SEO from '../components/SEO'
import { Container } from '../components/shared/Grid'
import { Flex } from '../components/shared/Helpers'
import Layout from '../layouts'
import useCollectionNodes from '../utils/useCollectionNodes'

const Glossary: React.FC = () => {
  const { getAllNodes, pushNode } = useCollectionNodes()

  return (
    <Layout>
      <SEO />
      <Container>
        <Hero />
        <Flex>
          <Sidebar getRefs={getAllNodes} />

          <div>
            <SidebarMobile getRefs={getAllNodes} />
            <Content pushRef={pushNode} />
          </div>
        </Flex>
      </Container>
    </Layout>
  )
}

export default Glossary
